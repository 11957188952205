.about-us{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .container-info{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .blue-line{
            width: 100%;
            height: 1px;
            border-bottom: solid 6px $colorCyan;
        }
        .container-item{
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            .order1{
                order: 1;
            }
            .order2{
                order: 2;
            }
            .img{
                width: 100%;
                height: 600px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .item{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 30px;
                h2{
                    text-align: center;
                    padding: 0;
                    font-weight: 800;
                    color: $colorCyan;
                    font-size: 32px;
                    line-height: 32px;
                    text-align: left;
                }
                .blue-spacer{
                    width: 100%;
                    height: 10px;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;
                    margin: 30px 0px;
                    .uno{
                        width: 30%;
                        border-bottom: 4px solid $colorCyan;
                    }
                    .dos{
                        width: 70%;
                        border-bottom: 1px solid $colorSecondary;
                    }
                }
                article{
                    width: 100%;
                    text-align: left;
                    padding: 0;
                    span{
                        font-weight: 600;
                    }
                    p{
                        font-size: 26px;
                        margin-bottom: 20px;
                        font-weight: 800;
                        color: $colorCyan;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px){
    .about-us{
        .container-info{
            .container-item{
                .img{
                    width: 50%;
                    height: auto;
                }
                .item{
                    width: 50%;
                    padding: 80px;
                }
            }
        }
    }   
}
