section{
    .about-us-h{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 100px 10px;
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 200px;
        .intro{
            width: 90%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            article{
                width: 100%;
                color: $colorCyan;
                font-weight: 600;
                text-align: center;
                font-size: 16px;
                line-height: 22px;
                padding: 10px 5px;
            }
        }
        .txt{
            width: 90%;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            margin-bottom: 100px;
            .separator-container{
                display: none;
                .separator1{
                    width: 100%;
                    height: 60px;
                    border-left: 4px solid $colorCyan;
                }
                .separator2{
                    width: 100%;
                    height: 100%;
                    border-right: 1px solid $colorMain;
                }
            }
            article{
                width: 100%;
                text-align: center;
                font-size: 16px;
                line-height: 22px;
                padding: 10px 0px;
                margin: 30px 0px;
                span{
                    font-weight: 600;
                }
                h2{
                    text-align: center;
                    padding: 0;
                    font-weight: 800;
                    color: $colorCyan;
                    font-size: 32px;
                    margin-bottom: 10px;
                }
                .btnM{
                    margin: 30px 0px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .about-us-h{
            .intro{
                width: 80%;
                article{
                    text-align: left;
                }
            }
            .txt{
                width: 80%;
                flex-wrap: nowrap;
                margin-bottom: 50px;
                .separator-container{
                    width: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 20px;
                    .separator1{
                        width: 100%;
                        height: 60px;
                        border-left: 4px solid $colorCyan;
                    }
                    .separator2{
                        width: 100%;
                        height: 100%;
                        border-right: 1px solid $colorMain;
                    }
                }
                article{
                    text-align: left;
                    padding: 10px 0px;
                    margin: 5px 0px;
                    h2{
                        text-align: left;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}