section{
    .contacto{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 5px 1px;
        h3{
            text-align: center;
            margin: 10px 0px;
            font-weight: 800;
            color: $colorWhite;
        }
        .container-form{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            order: 2;
            form{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                overflow: hidden;
                border: 1px solid $colorGray;
                padding-bottom: 30px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                background-color: $colorWhite;
                .container-title{
                    width: 100%;
                    background: rgb(19,182,226);
                    background: linear-gradient(180deg, rgba(19,182,226,1) 0%, rgba(12,142,163,1) 100%); 
                    padding: 10px 20px;
                    margin-bottom: 20px;
                }
                .container-input{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    padding: 5px 10px;
                    input{
                        width: 100%;
                        padding: 10px 20px;
                        border: 1px solid $colorGray;
                        margin: 5px 0px;
                        font-size: 16px;
                        border-radius: 30px;
                    }
                }
                .container-textarea{
                    width: 100%;
                    padding: 5px 10px;
                    textarea{
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 100px;
                        max-height: 400px;
                        border-radius: 30px;
                        border: 1px solid $colorGray;
                        padding: 20px;
                        font-size: 16px;
                    }
                }
                p{
                    color: $colorCyan;
                    margin-bottom: 10px;
                }
                .btnM{
                    width: 80%;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px){
    section{
        .contacto{
            padding: 5px 50px;
            .container-form{
                form{
                    width: 60%;
                    .container-input{
                        flex-direction: row;
                        gap: 10px;
                    }
                }
            }
        }
    }
}