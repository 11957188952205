section{
    .banner-home{
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;
        .container{
            width: 300px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding: 20px;
            article{
                width: 300px;
                .main-bh{
                    font-size: 32px;
                    font-weight: 600;
                    color: $colorWhite;
                    line-height: 38px;
                    text-align: center;
                } 
                .comp-bh{
                    font-size: 14px;
                    line-height: 16px;
                    color: $colorWhite;
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px){
    section{
        .banner-home{
            height: 920px;
            .container{
                width: 80%;
                align-items: flex-start;
                justify-content: center;
                article{
                    width: 500px;
                    .main-bh{
                        font-size: 54px;
                        line-height: 62px;
                        font-weight: 600;
                        color: $colorWhite;
                        text-align: left;
                    } 
                    .comp-bh{
                        font-size: 16px;
                        line-height: 18px;
                        color: $colorWhite;
                        text-align: left;
                    }
                }
            }
        }
    }
}
