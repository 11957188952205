.contacto-footer{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .container-form{
        width: auto;
        height: auto;
        margin-top: 50px;
        h3{
            text-align: left;
            color: $colorCyan;
            font-weight: 600;
            margin-bottom: 20px;
        }
        form{
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            input, textarea{
                width: 100%;
                padding: 10px;
                border: 1px solid $colorGray;
                font-size: 16px;
                border-radius: 30px;
                background-color: transparent;
                color: $colorWhite;
            }
            textarea{
                min-width: 100%;
                max-width: 100%;
                min-height: 100px;
                max-height: 400px;
                padding: 10px;
                border-radius: 30px;
                background-color: transparent;
                color: $colorWhite;
            }
            p{
                color: $colorCyan;
                margin-bottom: 20px;
            }
            .btnM{
                width: 100px;
                font-weight: 400;
                background-color: transparent;
                border: 2px solid $colorCyan;
                border-radius: 30px;
                color: $colorWhite;
            }
            .btnM:hover{
                color: $colorWhite;
                background-color: $colorCyan;
            }
        }
    }
}

@media only screen and (min-width: 1024px){
    .contacto-footer{
        .container-form{
            margin-top: 0;
            form{
                width: 500px;
            }
        }
    }
}