@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Fredericka+the+Great&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


$colorMain: #333;
$colorSecondary: #999;
$colorYellow: #edd150;
$colorCyan: #13B6E2;
$colorMagenta: #c32472;
$colorRed: #b03a44;
$colorWhite: #fff;
$colorGray: #eaeaea;

*{
	padding: 0;
	margin: 0;
	font-family: "DM Sans", sans-serif;
	font-weight: 400;
	color: $colorMain;
	text-decoration: none;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body{
	width: 100%;
	background-color: $colorWhite;
}

.appContainer{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main{
	flex-grow: 1;
}

section{
	width: 100%;
	max-width: 100vw;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

article{
	width: 90%;
	font-size: 16px;
	line-height: 30px;
	text-align: center;
	padding: 30px 10px;
}

.separator{
	width: 100%;
	height: auto;
	border-bottom: solid 6px $colorCyan;
	bottom: 0;
	position: absolute;
}

h1{
	font-size: 22px;
    text-align: center;
	padding: 5px;
}

h2{
	font-size: 36px;
    text-align: center;
	font-weight: 600;
	padding: 5px;
}
h3{
	font-size: 26px;
	text-align: center;
}
h4{
	font-size: 22px;
	text-align: center;
}

.banner-section{
	width: 100%;
	height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto;
	border-bottom: 6px solid $colorCyan;
	.container-banner-section{
		width: 80%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		article{
			width: 90%;
			font-size: 32px;
			line-height: 38px;
			font-weight: 600;
			color: $colorWhite;
			text-align: center;
			span{
				font-weight: 600;
				color: $colorCyan;
			}
		}
		p{
			width: 90%;
			color: $colorWhite;
			text-align: center;
		}
		svg{
			fill: $colorWhite;
			width: 60px;
		}
	}
}

.btnM{
	width: auto;
	height: auto;
	text-align: center;
	font-size: 18px;
	font-weight: 800;
	padding: 10px 20px;
	background-color: $colorCyan;
	border: 2px solid $colorCyan;
	border-radius: 30px;
	color: $colorWhite;
	cursor: pointer;
}
.btnM:hover{
	color: $colorCyan;
	background-color: $colorWhite;
	transition: 0.2s;
}

.btnO{
	width: auto;
	height: auto;
	text-align: center;
	font-size: 18px;
	font-weight: 800;
	padding: 10px 20px;
	background-color: $colorCyan;
	border: 2px solid $colorCyan;
	border-radius: 30px;
	color: $colorWhite;
	background: rgba(0,0,0, 0.3)
}
.btnO:hover{
	color: $colorWhite;
	background-color: $colorCyan;
	transition: 0.2s;
	cursor: pointer;
}

.btnN{
	width: auto;
	height: auto;
	padding: 10px 30px;
	background-color: transparent;
	border: 1px solid $colorMain;
	font-size: 18px;
	color: $colorMain;
}
.btnN:hover{
	background-color: $colorMain;
	color: $colorWhite;
	transition: 0.2s;
	cursor: pointer;
}

.btnNSB{
	width: auto;
	height: auto;
	padding: 10px 30px;
	background-color: transparent;
	border: 1px solid transparent;
	font-size: 18px;
	color: $colorMain;
	margin: 20px;
}
.btnNSB:hover{
	color: $colorSecondary;
	transition: 0.3s;
	cursor: pointer;
}

@import 'components/Assets/loading';
@import 'components/Assets/not-a-404';

@import 'components/NavBar/navbar';

@import 'components/Home/Banners/banner-home';
@import 'components/Home/Products/products';
@import 'components/Home/AboutUs/about-us';
@import 'components/Home/KnowMore/know-more';

@import 'components/Solutions/solutions';
@import 'components/AboutUs/about-us';
@import 'components/Products/products';

@import 'components/Contacto/contacto';
@import 'components/ContactoForm/contacto-form';

@import 'components/Footer/footer';
@import 'components/ContactoFormFooter/contacto-form-footer';

@media only screen and (min-width: 1024px){
    article{
		width: 60%;
	}  
	.banner-section{
		.container-banner-section{
			align-items: flex-start;
			article{
				width: 60%;
				font-size: 54px;
				line-height: 62px;
				text-align: left;
			}
			p{
				width: 60%;
				text-align: left;
			}
		}
	}
}