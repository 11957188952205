.products{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .contacto{
        margin-bottom: 100px;
    }
    .intro{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(19,182,226);
        background: linear-gradient(180deg, rgba(19,182,226,1) 0%, rgba(12,142,163,1) 100%);            
        article{
            width: 80%;
            text-align: center;
            color: $colorWhite;
            font-weight: 800;
            font-size: 26px;
        }
    }
    .container-products{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 50px 20px;
        gap: 20px;
        .product{
            width: 280px;
            height: 280px;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            box-shadow: inset 0 0 0 140px rgba(0,0,0,.6);
            article{
                width: 80%;
                height: 220px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px;
                color: $colorWhite;
                font-size: 14px;
                line-height: 16px;
            }
            .container-svg{
                width: 30px;
                height: 30px;
                background-color: $colorCyan;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 190px;
                svg{
                    width: 12px;
                    fill: $colorWhite;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px){  
    .products{
        width: 100%;
        .container-products{
            width: 70%;
            padding: 50px 10px;
            .product{
                width: 240px;
                height: 240px;
                box-shadow: inset 0 0 0 0px rgba(0,0,0,.6);
                article{
                    display: none;
                }
                .container-svg{
                    margin-left: 180px;
                    margin-top: 180px;
                }
            }
            .product:hover{
                cursor: pointer;
                box-shadow: inset 0 0 0 140px rgba(0,0,0,.6);
                article{
                    display: flex;
                }
                .container-svg{
                    display: none;
                }
            }
        }
    }
}
