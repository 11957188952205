@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Fredericka+the+Great&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: #333;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  background-color: #fff;
}

.appContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

section {
  width: 100%;
  max-width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

article {
  width: 90%;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  padding: 30px 10px;
}

.separator {
  width: 100%;
  height: auto;
  border-bottom: solid 6px #13B6E2;
  bottom: 0;
  position: absolute;
}

h1 {
  font-size: 22px;
  text-align: center;
  padding: 5px;
}

h2 {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
  padding: 5px;
}

h3 {
  font-size: 26px;
  text-align: center;
}

h4 {
  font-size: 22px;
  text-align: center;
}

.banner-section {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border-bottom: 6px solid #13B6E2;
}
.banner-section .container-banner-section {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.banner-section .container-banner-section article {
  width: 90%;
  font-size: 32px;
  line-height: 38px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.banner-section .container-banner-section article span {
  font-weight: 600;
  color: #13B6E2;
}
.banner-section .container-banner-section p {
  width: 90%;
  color: #fff;
  text-align: center;
}
.banner-section .container-banner-section svg {
  fill: #fff;
  width: 60px;
}

.btnM {
  width: auto;
  height: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  padding: 10px 20px;
  background-color: #13B6E2;
  border: 2px solid #13B6E2;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
}

.btnM:hover {
  color: #13B6E2;
  background-color: #fff;
  transition: 0.2s;
}

.btnO {
  width: auto;
  height: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  padding: 10px 20px;
  background-color: #13B6E2;
  border: 2px solid #13B6E2;
  border-radius: 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}

.btnO:hover {
  color: #fff;
  background-color: #13B6E2;
  transition: 0.2s;
  cursor: pointer;
}

.btnN {
  width: auto;
  height: auto;
  padding: 10px 30px;
  background-color: transparent;
  border: 1px solid #333;
  font-size: 18px;
  color: #333;
}

.btnN:hover {
  background-color: #333;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
}

.btnNSB {
  width: auto;
  height: auto;
  padding: 10px 30px;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 18px;
  color: #333;
  margin: 20px;
}

.btnNSB:hover {
  color: #999;
  transition: 0.3s;
  cursor: pointer;
}

.loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0px;
}
.loading h2 {
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 30px;
}
.loading img {
  width: 200px;
}

.notA404 {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
}
.notA404 h4 {
  color: #fff;
  margin: 30px;
}
.notA404 div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.notA404 div img {
  max-width: 80%;
}

nav {
  width: 100%;
  height: auto;
  font-size: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #fff;
  z-index: 100;
  margin-top: 10px;
}
nav svg {
  fill: #333;
  width: 40px;
  padding: 5px;
}
nav svg:hover {
  cursor: pointer;
}
nav .navbar {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
nav .navbar .navbar-buttons {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav .navbar .navbar-buttons .logo {
  max-height: 80px;
  max-width: auto;
}
nav .navbar .navbar-buttons .svg {
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
}
nav .navbar .navbar-buttons svg {
  width: 40px;
  margin: 10px;
}
nav .navbar .navbar-buttons .brand {
  font-size: 56px;
  font-weight: 900;
}
nav .navbar .navbar-home-menu {
  display: none;
}
nav .buttons-open {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #fff;
  margin-top: 140px;
  padding: 50px 0px;
}
nav .buttons-open .button {
  width: 140px;
  height: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  padding: 20px;
  border-bottom: 2px solid #13B6E2;
}
nav .buttons-open .button:hover {
  color: #999;
  transition: 0.2s;
}
nav .buttons-open .bordernone {
  border: none;
}
nav .buttons-open .button-contact {
  width: 140px;
  height: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  padding: 5px;
  border: 2px solid #13B6E2;
  border-radius: 30px;
  color: #13B6E2;
}
nav .buttons-open .button-contact:hover {
  color: #fff;
  background-color: #13B6E2;
  transition: 0.2s;
}
nav .buttons-closed {
  display: none;
}

@media only screen and (min-width: 1024px) {
  nav .navbar .navbar-home-menu {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  nav .navbar .navbar-home-menu .button {
    width: auto;
    height: auto;
    text-align: center;
    font-size: 18px;
    padding: 10px;
    margin: 10px 50px;
    border-bottom: 2px solid #fff;
    font-weight: 800;
  }
  nav .navbar .navbar-home-menu .button:hover {
    border-bottom: 2px solid #13B6E2;
    transition: 0.2s;
  }
  nav .navbar .navbar-home-menu .button-contact {
    width: 140px;
    height: auto;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    padding: 5px;
    border: 2px solid #13B6E2;
    border-radius: 30px;
    color: #13B6E2;
    transition: 0.2s;
  }
  nav .navbar .navbar-home-menu .button-contact:hover {
    color: #fff;
    background-color: #13B6E2;
    transition: 0.2s;
  }
}
section .banner-home {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
section .banner-home .container {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
}
section .banner-home .container article {
  width: 300px;
}
section .banner-home .container article .main-bh {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  line-height: 38px;
  text-align: center;
}
section .banner-home .container article .comp-bh {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  section .banner-home {
    height: 920px;
  }
  section .banner-home .container {
    width: 80%;
    align-items: flex-start;
    justify-content: center;
  }
  section .banner-home .container article {
    width: 500px;
  }
  section .banner-home .container article .main-bh {
    font-size: 54px;
    line-height: 62px;
    font-weight: 600;
    color: #fff;
    text-align: left;
  }
  section .banner-home .container article .comp-bh {
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    text-align: left;
  }
}
section .products-h {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
section .products-h .product-box {
  width: 100%;
  height: 400px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
section .products-h .product-box h2 {
  color: #fff;
  font-size: 36px;
  text-align: center;
  font-weight: 800;
}
section .products-h .product-box .btnO {
  width: 160px;
}

@media only screen and (min-width: 1024px) {
  section .products-h {
    flex-wrap: nowrap;
  }
  section .products-h .product-box {
    width: 20%;
    height: 500px;
  }
  section .products-h .product-box h2 {
    font-size: 50px;
  }
}
section .about-us-h {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 10px;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 200px;
}
section .about-us-h .intro {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
section .about-us-h .intro article {
  width: 100%;
  color: #13B6E2;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 5px;
}
section .about-us-h .txt {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-bottom: 100px;
}
section .about-us-h .txt .separator-container {
  display: none;
}
section .about-us-h .txt .separator-container .separator1 {
  width: 100%;
  height: 60px;
  border-left: 4px solid #13B6E2;
}
section .about-us-h .txt .separator-container .separator2 {
  width: 100%;
  height: 100%;
  border-right: 1px solid #333;
}
section .about-us-h .txt article {
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 0px;
  margin: 30px 0px;
}
section .about-us-h .txt article span {
  font-weight: 600;
}
section .about-us-h .txt article h2 {
  text-align: center;
  padding: 0;
  font-weight: 800;
  color: #13B6E2;
  font-size: 32px;
  margin-bottom: 10px;
}
section .about-us-h .txt article .btnM {
  margin: 30px 0px;
}

@media only screen and (min-width: 1000px) {
  section .about-us-h .intro {
    width: 80%;
  }
  section .about-us-h .intro article {
    text-align: left;
  }
  section .about-us-h .txt {
    width: 80%;
    flex-wrap: nowrap;
    margin-bottom: 50px;
  }
  section .about-us-h .txt .separator-container {
    width: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
  }
  section .about-us-h .txt .separator-container .separator1 {
    width: 100%;
    height: 60px;
    border-left: 4px solid #13B6E2;
  }
  section .about-us-h .txt .separator-container .separator2 {
    width: 100%;
    height: 100%;
    border-right: 1px solid #333;
  }
  section .about-us-h .txt article {
    text-align: left;
    padding: 10px 0px;
    margin: 5px 0px;
  }
  section .about-us-h .txt article h2 {
    text-align: left;
    margin-bottom: 20px;
  }
}
section .know-more {
  width: 100%;
  height: auto;
}
section .know-more .img-txt {
  width: 100%;
  height: auto;
  padding: 50px 20px;
}
section .know-more .img-txt h2 {
  color: #fff;
}
section .know-more .contact-txt {
  width: 100%;
  height: auto;
  padding: 30px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  background: rgb(19, 182, 226);
  background: linear-gradient(180deg, rgb(19, 182, 226) 0%, rgb(12, 142, 163) 100%);
}
section .know-more .contact-txt article {
  width: auto;
  color: #fff;
  font-size: 22px;
}
section .know-more .contact-txt .btnM {
  background-color: transparent;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
section .know-more .contact-txt .btnM svg {
  fill: #fff;
  width: 10px;
  margin-left: 10px;
}
section .know-more .contact-txt .btnM:hover {
  color: #13B6E2;
  background-color: #fff;
  transition: 0.2s;
}
section .know-more .contact-txt .btnM:hover svg {
  fill: #13B6E2;
}

.solutions .intro-solutions {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
}
.solutions .intro-solutions .container-solutions {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.solutions .intro-solutions .container-solutions article {
  width: 100%;
  text-align: center;
  padding: 30px 20px;
}
.solutions .intro-solutions .container-solutions article span {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
}
.solutions .intro-solutions .container-solutions .separator-solutions {
  width: 100%;
  height: 1px;
  background-color: #999;
}
.solutions .container-card {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin-bottom: 100px;
}
.solutions .container-card .card-solutions {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 30px;
  overflow: hidden;
  background: rgb(19, 182, 226);
  background: linear-gradient(180deg, rgb(19, 182, 226) 0%, rgb(12, 142, 163) 100%);
}
.solutions .container-card .card-solutions .txt {
  width: 100%;
  padding: 30px;
  color: #fff;
  font-size: 18px;
  text-align: center;
}
.solutions .container-card .card-solutions .img {
  width: 100%;
  height: 340px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.solutions .container-items {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.solutions .container-items .items-solutions {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.solutions .container-items .items-solutions .items {
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.solutions .container-items .items-solutions .items h3 {
  height: 50px;
  display: flex;
  align-items: center;
  color: #13B6E2;
  font-weight: 800;
  font-size: 18px;
}
.solutions .container-items .items-solutions .items article {
  width: 100%;
  height: auto;
  border-top: 2px solid #13B6E2;
  margin-top: 10px;
  padding: 20px 10px;
  font-size: 16px;
  line-height: 18px;
}
.solutions .container-map {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin: 50px 0px 100px 0px;
}
.solutions .container-map .img {
  width: 100%;
  height: auto;
}
.solutions .container-map .img img {
  width: 100%;
}
.solutions .container-map .separator-container {
  display: none;
}
.solutions .container-map .separator-container .separator1 {
  width: 100%;
  height: 60px;
  border-left: 4px solid #13B6E2;
  position: absolute;
}
.solutions .container-map .separator-container .separator2 {
  width: 100%;
  height: auto;
  border-right: 1px solid #333;
}
.solutions .container-map article {
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 20px;
}
.solutions .container-map article span {
  font-weight: 600;
}
.solutions .container-map article h2 {
  text-align: center;
  padding: 0;
  font-weight: 800;
  color: #13B6E2;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  .solutions .intro-solutions {
    width: 100%;
    padding: 50px 0px;
  }
  .solutions .intro-solutions .container-solutions {
    width: 80%;
  }
  .solutions .intro-solutions .container-solutions article {
    width: 100%;
    text-align: left;
  }
  .solutions .container-card {
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 100px;
  }
  .solutions .container-card .card-solutions {
    width: 80%;
  }
  .solutions .container-card .card-solutions .txt {
    width: 60%;
    padding: 50px 20px 50px 50px;
    font-size: 22px;
  }
  .solutions .container-card .card-solutions .img {
    width: 40%;
    height: auto;
  }
  .solutions .container-items .items-solutions .items h3 {
    align-items: center;
  }
  .solutions .container-items .items-solutions .items article {
    width: 100%;
    height: 180px;
    padding: 20px 10px;
  }
  .solutions .container-map {
    width: 80%;
    flex-wrap: nowrap;
    margin: 100px 0px 100px 0px;
  }
  .solutions .container-map .img {
    width: 50%;
  }
  .solutions .container-map .separator-container {
    display: flex;
  }
  .solutions .container-map article {
    width: 50%;
    text-align: left;
  }
  .solutions .container-map article h2 {
    text-align: left;
  }
}
.about-us {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-us .container-info {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.about-us .container-info .blue-line {
  width: 100%;
  height: 1px;
  border-bottom: solid 6px #13B6E2;
}
.about-us .container-info .container-item {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
.about-us .container-info .container-item .order1 {
  order: 1;
}
.about-us .container-info .container-item .order2 {
  order: 2;
}
.about-us .container-info .container-item .img {
  width: 100%;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-us .container-info .container-item .item {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.about-us .container-info .container-item .item h2 {
  text-align: center;
  padding: 0;
  font-weight: 800;
  color: #13B6E2;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
}
.about-us .container-info .container-item .item .blue-spacer {
  width: 100%;
  height: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}
.about-us .container-info .container-item .item .blue-spacer .uno {
  width: 30%;
  border-bottom: 4px solid #13B6E2;
}
.about-us .container-info .container-item .item .blue-spacer .dos {
  width: 70%;
  border-bottom: 1px solid #999;
}
.about-us .container-info .container-item .item article {
  width: 100%;
  text-align: left;
  padding: 0;
}
.about-us .container-info .container-item .item article span {
  font-weight: 600;
}
.about-us .container-info .container-item .item article p {
  font-size: 26px;
  margin-bottom: 20px;
  font-weight: 800;
  color: #13B6E2;
}

@media only screen and (min-width: 1024px) {
  .about-us .container-info .container-item .img {
    width: 50%;
    height: auto;
  }
  .about-us .container-info .container-item .item {
    width: 50%;
    padding: 80px;
  }
}
.products {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.products .contacto {
  margin-bottom: 100px;
}
.products .intro {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(19, 182, 226);
  background: linear-gradient(180deg, rgb(19, 182, 226) 0%, rgb(12, 142, 163) 100%);
}
.products .intro article {
  width: 80%;
  text-align: center;
  color: #fff;
  font-weight: 800;
  font-size: 26px;
}
.products .container-products {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  gap: 20px;
}
.products .container-products .product {
  width: 280px;
  height: 280px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 140px rgba(0, 0, 0, 0.6);
}
.products .container-products .product article {
  width: 80%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}
.products .container-products .product .container-svg {
  width: 30px;
  height: 30px;
  background-color: #13B6E2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 190px;
}
.products .container-products .product .container-svg svg {
  width: 12px;
  fill: #fff;
}

@media only screen and (min-width: 1024px) {
  .products {
    width: 100%;
  }
  .products .container-products {
    width: 70%;
    padding: 50px 10px;
  }
  .products .container-products .product {
    width: 240px;
    height: 240px;
    box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.6);
  }
  .products .container-products .product article {
    display: none;
  }
  .products .container-products .product .container-svg {
    margin-left: 180px;
    margin-top: 180px;
  }
  .products .container-products .product:hover {
    cursor: pointer;
    box-shadow: inset 0 0 0 140px rgba(0, 0, 0, 0.6);
  }
  .products .container-products .product:hover article {
    display: flex;
  }
  .products .container-products .product:hover .container-svg {
    display: none;
  }
}
section .banner-contacto {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
}
section .banner-contacto img {
  width: 80%;
}
section .contacto-info {
  width: 100%;
  height: auto;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1000px) {
  section .banner-contacto img {
    width: 500px;
  }
}
section .contacto {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px 1px;
}
section .contacto h3 {
  text-align: center;
  margin: 10px 0px;
  font-weight: 800;
  color: #fff;
}
section .contacto .container-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  order: 2;
}
section .contacto .container-form form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  padding-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
}
section .contacto .container-form form .container-title {
  width: 100%;
  background: rgb(19, 182, 226);
  background: linear-gradient(180deg, rgb(19, 182, 226) 0%, rgb(12, 142, 163) 100%);
  padding: 10px 20px;
  margin-bottom: 20px;
}
section .contacto .container-form form .container-input {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 10px;
}
section .contacto .container-form form .container-input input {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #eaeaea;
  margin: 5px 0px;
  font-size: 16px;
  border-radius: 30px;
}
section .contacto .container-form form .container-textarea {
  width: 100%;
  padding: 5px 10px;
}
section .contacto .container-form form .container-textarea textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 400px;
  border-radius: 30px;
  border: 1px solid #eaeaea;
  padding: 20px;
  font-size: 16px;
}
section .contacto .container-form form p {
  color: #13B6E2;
  margin-bottom: 10px;
}
section .contacto .container-form form .btnM {
  width: 80%;
}

@media only screen and (min-width: 1024px) {
  section .contacto {
    padding: 5px 50px;
  }
  section .contacto .container-form form {
    width: 60%;
  }
  section .contacto .container-form form .container-input {
    flex-direction: row;
    gap: 10px;
  }
}
footer {
  width: 100%;
  height: auto;
  background-color: #333;
}
footer .legal {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
}
footer .container-footer {
  width: 100%;
  height: auto;
  padding: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
footer .container-footer .container-locations {
  width: 160px;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
footer .container-footer .container-locations img {
  width: 140px;
  padding-top: 5px;
}
footer .container-footer .container-locations div article {
  width: 140px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  padding: 10px 0px;
  border-bottom: 1px solid #999;
}
footer .container-footer .container-locations div .nolines {
  border: none;
}

@media only screen and (min-width: 560px) {
  footer .container-footer {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  footer .container-footer .container-locations {
    width: 160px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }
}
.contacto-footer {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contacto-footer .container-form {
  width: auto;
  height: auto;
  margin-top: 50px;
}
.contacto-footer .container-form h3 {
  text-align: left;
  color: #13B6E2;
  font-weight: 600;
  margin-bottom: 20px;
}
.contacto-footer .container-form form {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contacto-footer .container-form form input, .contacto-footer .container-form form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #eaeaea;
  font-size: 16px;
  border-radius: 30px;
  background-color: transparent;
  color: #fff;
}
.contacto-footer .container-form form textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 400px;
  padding: 10px;
  border-radius: 30px;
  background-color: transparent;
  color: #fff;
}
.contacto-footer .container-form form p {
  color: #13B6E2;
  margin-bottom: 20px;
}
.contacto-footer .container-form form .btnM {
  width: 100px;
  font-weight: 400;
  background-color: transparent;
  border: 2px solid #13B6E2;
  border-radius: 30px;
  color: #fff;
}
.contacto-footer .container-form form .btnM:hover {
  color: #fff;
  background-color: #13B6E2;
}

@media only screen and (min-width: 1024px) {
  .contacto-footer .container-form {
    margin-top: 0;
  }
  .contacto-footer .container-form form {
    width: 500px;
  }
}
@media only screen and (min-width: 1024px) {
  article {
    width: 60%;
  }
  .banner-section .container-banner-section {
    align-items: flex-start;
  }
  .banner-section .container-banner-section article {
    width: 60%;
    font-size: 54px;
    line-height: 62px;
    text-align: left;
  }
  .banner-section .container-banner-section p {
    width: 60%;
    text-align: left;
  }
}/*# sourceMappingURL=styles.css.map */