.solutions{
    .intro-solutions{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0px;
        .container-solutions{
            width: 90%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;  
            article{
                width: 100%;
                text-align: center;
                padding: 30px 20px;
                span{
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 26px;
                }
            }
            .separator-solutions{
                width: 100%;
                height: 1px;
                background-color: $colorSecondary;
            }
        }
    }
    .container-card{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        margin-bottom: 100px;
        .card-solutions{
            width: 90%;
            height: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border-radius: 30px;
            overflow: hidden;
            background: rgb(19,182,226);
            background: linear-gradient(180deg, rgba(19,182,226,1) 0%, rgba(12,142,163,1) 100%);            
            .txt{
                width: 100%;
                padding: 30px;
                color: $colorWhite;
                font-size: 18px;
                text-align: center;
            }
            .img{
                width: 100%;
                height: 340px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto;
            }
        }
    }
    .container-items{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .items-solutions{
            width: 90%;
            height: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            .items{
                width: 300px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 10px;
                h3{
                    height: 50px;
                    display: flex;
                    align-items: center;
                    color: $colorCyan;
                    font-weight: 800;
                    font-size: 18px;
                }
                article{
                    width: 100%;
                    height: auto;
                    border-top: 2px solid $colorCyan;
                    margin-top: 10px;
                    padding: 20px 10px;
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
    .container-map{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        margin: 50px 0px 100px 0px;
        .img{
            width: 100%;
            height: auto;
            img{
                width: 100%;
            }
        }
        .separator-container{
            display: none;
            .separator1{
                width: 100%;
                height: 60px;
                border-left: 4px solid $colorCyan;
                position: absolute;
            }
            .separator2{
                width: 100%;
                height: auto;
                border-right: 1px solid $colorMain;
            }
        }
        article{
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 22px;
            padding: 10px 20px;
            span{
                font-weight: 600;
            }
            h2{
                text-align: center;
                padding: 0;
                font-weight: 800;
                color: $colorCyan;
                font-size: 32px;
                line-height: 32px;
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (min-width: 1024px){
    .solutions{
        .intro-solutions{
            width: 100%;
            padding: 50px 0px;
            .container-solutions{
                width: 80%;
                article{
                    width: 100%;
                    text-align: left;
                }
            }
        }
        .container-card{
            width: 100%;
            padding: 0px 20px;
            margin-bottom: 100px;
            .card-solutions{
                width: 80%;
                .txt{
                    width: 60%;
                    padding: 50px 20px 50px 50px;
                    font-size: 22px;
                }
                .img{
                    width: 40%;
                    height: auto;
                }
            }
        }
        .container-items{
            .items-solutions{
                .items{
                    h3{
                        align-items: center;
                    }
                    article{
                        width: 100%;
                        height: 180px;
                        padding: 20px 10px;
                    }
                }
            }
        }
        .container-map{
            width: 80%;
            flex-wrap: nowrap;
            margin: 100px 0px 100px 0px;
            .img{
                width: 50%;
            }
            .separator-container{
                display: flex;
            }
            article{
                width: 50%;
                text-align: left;
                h2{
                    text-align: left;
                }
            }
        }
    }    
}
