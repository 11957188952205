.notA404{
	width: 100%;
    height: 600px;
	display: flex;
    flex-direction: column;
	align-items: center;
    justify-content: center;
    background-color: $colorMain;
    h4{
        color: $colorWhite;
        margin: 30px;
    }
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;
        img{
            max-width: 80%;
        }
    }
}