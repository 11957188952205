footer{
	width: 100%;
	height: auto;
	background-color: $colorMain;
	.legal{
		width: 100%;
		background-color: $colorWhite;
		padding: 10px;
		font-size: 12px;
		text-align: center;
	}
	.container-footer{
		width: 100%;
		height: auto;
		padding: 50px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		.container-locations{
			width: 160px;
			height: auto;
			display: flex;
			flex-wrap: nowrap;
			align-items: flex-start;
			justify-content: center;
			gap: 20px;
			img{
				width: 140px;
				padding-top: 5px;
			}
			div{
				article{
					width: 140px;
					color: $colorWhite;
					font-size: 16px;
					line-height: 20px;
					text-align: left;
					padding: 10px 0px;
					border-bottom: 1px solid $colorSecondary;
				}
				.nolines{
					border: none;
				}
			}
		}
	}
}

@media only screen and (min-width: 560px){
	footer{
		.container-footer{
			flex-wrap: wrap;
			justify-content: space-evenly;
			.container-locations{
				width: 160px;
				height: auto;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: center;
				gap: 20px;
			}
		}
	}
}
