section{
    .products-h{
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .product-box{
            width: 100%;
            height: 400px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto;
            h2{
                color: $colorWhite;
                font-size: 36px;
                text-align: center;
                font-weight: 800;
            }
            .btnO{
                width: 160px;
            }
        }
    }
}

@media only screen and (min-width: 1024px){
    section{
        .products-h{
            flex-wrap: nowrap;
            .product-box{
                width: 20%;
                height: 500px;
                h2{
                    font-size: 50px;
                }
            }
        }
    }
}