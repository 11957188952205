section{
    .know-more{
        width: 100%;
        height: auto;
        .img-txt{
            width: 100%;
            height: auto;
            padding: 50px 20px;
            h2{
                color: $colorWhite;
            }
        }
        .contact-txt{
            width: 100%;
            height: auto;
            padding: 30px 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            background: rgb(19,182,226);
            background: linear-gradient(180deg, rgba(19,182,226,1) 0%, rgba(12,142,163,1) 100%);            
            article{
                width: auto;
                color: $colorWhite;
                font-size: 22px;
            }
            .btnM{
                background-color: transparent;
                border: 2px solid $colorWhite;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: nowrap;
                svg{
                    fill: $colorWhite;
                    width: 10px;
                    margin-left: 10px;
                }
            }
            .btnM:hover{
                color: $colorCyan;
                background-color: $colorWhite;
                transition: 0.2s;
                svg{
                    fill: $colorCyan;
                }
            }
        }
    }
}